@import "./fonts.scss";

.wall-top-header {
  background: linear-gradient(0deg, rgba(16, 16, 16, 0) 0%, #101010 100%);
  position: fixed;
  top: 0;
  left: 0;
  padding: 3rem 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 100;
  mask: linear-gradient(black, black, transparent);
  backdrop-filter: blur(0.25rem);
}

.wall-app-drawer {
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100vw;
  background: rgba(16, 16, 16, 0.6);
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem;
  box-shadow: 4px 4px 48px 0px rgba(16, 16, 16, 0.8) inset;
  transform: translateY(-100%);
  backdrop-filter: blur(1rem);
}

.wall-hotspot-grid {
  display: grid;
  grid-gap: 1rem;
}

.wall-landing-container-outer {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.wall-landing-container-inner {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
}

.wall-landing-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  // scroll-snap-type: both mandatory;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  padding: 5rem 1rem;
}

.grid-item-hotspot-preview {
  display: grid;
  width: 100%;
  padding: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 8rem;
  grid-gap: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .large {
    grid-row: span 2;
    grid-column: span 2;
  }

  .host-details {
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;

    span:first-child {
      font-size: 0.625rem;
      opacity: 0.32;
    }

    p {
      font-weight: 700;
      margin: 2rem 0 0.5rem;
    }

    span:last-child {
      opacity: 0.48;
      font-size: 0.875rem;
    }
  }
}

.hotspot-info-header {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  opacity: 0.34;
  align-content: center;
  gap: 0.5rem;
  padding: 4rem 1rem 1rem;
  opacity: 0.5;

  .back-btn {
    margin-right: auto;
  }
}

// Grid card animations
.wall-hotspot-grid-item {
  position: relative;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  font-size: 30px;
  height: calc(100vh - 13rem);
  width: calc(100vw - 6rem);
  background-size: cover;
  background-position: center;
  filter: grayscale(1) blur(0.25rem);
  border-radius: 0.5rem;
  transition: opacity 0.5s ease-in-out, height 1s ease-in-out 0.5s,
    width 1s ease-in-out 0.5s;
  .grid-item-header,
  .wall-hotspot-grid-title {
    opacity: 0;
  }
}

.wall-hotspot-grid-item.active,
.wall-hotspot-grid-item:active {
  filter: none;
  .grid-item-header,
  .wall-hotspot-grid-title {
    opacity: 1;
  }
}

.wall-hotspot-grid-item.expanded {
  height: 60vh;
  width: 90vw;
  scroll-snap-align: center, none;
}

.wall-app-drawer.hide {
  transform: translateY(100%);
}

.wall-top-header.hide {
  transform: translateY(-100%);
}

.wall-app-drawer,
.wall-top-header {
  transition: all 1s ease-in-out;
}

.wall-landing-container.hasExpandedCard {
  .wall-hotspot-grid-item:not(.expanded) {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

.wall-landing-container.hasExpandedCard {
  overflow-x: hidden;
  padding: 1rem;
  grid-gap: 0;
}

.wall-wrapper {
  padding: 3rem 1rem 1rem 1rem;
}

.grid-item-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  padding: 1rem;
  gap: 0.75rem;

  .host-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
  }

  .hotspot-details > p:first-child {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .hotspot-details > p:last-child {
    font-size: 0.75rem;
    opacity: 0.72;
  }

  .invitees {
    font-size: 0.875rem;
    color: gray;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    margin-left: auto;
  }
}

.wall-hotspot-grid-title {
  padding: 1rem;
  position: absolute;
  bottom: 1.5rem;
  left: 0;

  .hotspot-title {
    font-size: 2.5rem;
    margin: 0.75rem 0;
    width: 70%;
  }
  .hotspot-info {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.875rem;
    color: gray;
  }
}

// v0.3
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.hotspot-grid-item {
  position: relative;
  scroll-snap-align: center;
  // scroll-snap-stop: always;
  font-size: 30px;
  height: calc(100vh - 8rem);
  width: calc(100vw - 4rem);
  background-size: cover;
  background-position: center;
  transition: height 1s ease-in, width 1s ease-in;
  will-change: filter;
  filter: grayscale(1) blur(0.25rem);
}

.hotspot-grid-item.active {
  // z-index: 1000;
  height: 60vh;
  width: calc(100vw - 2rem);
  margin: auto;
  // margin-top: 8rem;
}

.hotspot-grid-item.intersecting,
.hotspot-grid-item.active {
  filter: grayscale(0) blur(0);
}
